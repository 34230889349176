/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
    isSplash: false, // Change this to false if you don't want Splash screen.
};


//Home Page
const greeting = {
    title: "Pratik Daigavane",
    logo_name: "Pratik Daigavane",
    nickname: "flameslayer",
    subTitle: "Full Stack Developer 🚀 | Cloud and DevOps Enthusiast 🌩️ | Loves System Design 👨‍💻 ",
    resumeLink: "https://link.pratikdaigavane.me/resume",
    portfolio_repository: "https://github.com/pratikdaigavane/personalPortfolio",
};

const socialMediaLinks = {
    /* Your Social Media Link */
    github: "https://github.com/pratikdaigavane",
    linkedin: "https://www.linkedin.com/in/pratikdaigavane/",
    gmail: "mail@pratikd.in",
    instagram: "https://www.instagram.com/pratikdaigavane/"
};

const skills = {
    data: [{
            title: "Full Stack Development",
            fileName: "FullStackImg",
            skills: [
                "⚡ Building scalable backends using Django and Express",
                "⚡ Building responsive website front end using React-Redux"
            ],
            softwareSkills: [{
                    skillName: "HTML5",
                    fontAwesomeClassname: "simple-icons:html5",
                    style: {
                        color: "#E34F26"
                    }
                },
                {
                    skillName: "CSS3",
                    fontAwesomeClassname: "fa-css3",
                    style: {
                        color: "#1572B6"
                    }
                },
                {
                    skillName: "JavaScript",
                    fontAwesomeClassname: "simple-icons:javascript",
                    style: {
                        backgroundColor: "#000000",
                        color: "#F7DF1E"
                    }
                },
                {
                    skillName: "ReactJS",
                    fontAwesomeClassname: "simple-icons:react",
                    style: {
                        color: "#61DAFB"
                    }
                },
                {
                    skillName: "NodeJS",
                    fontAwesomeClassname: "simple-icons:node-dot-js",
                    style: {
                        color: "#339933"
                    }
                },
                {
                    skillName: "NPM",
                    fontAwesomeClassname: "simple-icons:npm",
                    style: {
                        color: "#CB3837"
                    }
                },
                {
                    skillName: "Yarn",
                    fontAwesomeClassname: "simple-icons:yarn",
                    style: {
                        color: "#2C8EBB"
                    }
                },
                {
                    skillName: "Gatsby",
                    fontAwesomeClassname: "simple-icons:gatsby",
                    style: {
                        color: "#663399"
                    }
                },
                {
                    skillName: "Django",
                    fontAwesomeClassname: "simple-icons:django",
                    style: {
                        color: "#103E2E"
                    }
                },
            ]
        },
        {
            title: "Cloud Infra-Architecture",
            fileName: "CloudInfraImg",
            skills: [
                "⚡ Experience working on multiple cloud platforms",
                "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
            ],
            softwareSkills: [{
                    skillName: "GCP",
                    fontAwesomeClassname: "simple-icons:googlecloud",
                    style: {
                        color: "#4285F4"
                    }
                },
                {
                    skillName: "AWS",
                    fontAwesomeClassname: "simple-icons:amazonaws",
                    style: {
                        color: "#FF9900"
                    }
                },
                {
                    skillName: "Azure",
                    fontAwesomeClassname: "simple-icons:microsoftazure",
                    style: {
                        color: "#0089D6"
                    }
                },
                {
                    skillName: "Firebase",
                    fontAwesomeClassname: "simple-icons:firebase",
                    style: {
                        color: "#FFCA28"
                    }
                },
                {
                    skillName: "PostgreSQL",
                    fontAwesomeClassname: "simple-icons:postgresql",
                    style: {
                        color: "#336791"
                    }
                },
                {
                    skillName: "MongoDB",
                    fontAwesomeClassname: "simple-icons:mongodb",
                    style: {
                        color: "#47A248"
                    }
                }
            ]
        },
        {
            title: "DevOps",
            fileName: "DevOpsImg",
            skills: [
                "⚡ Automating the process of deployment by building CI/CD Pipelines ",
                "⚡ Using docker to containerise the project",
                "⚡ Orchestrating containers using Kubernetes"
            ],
            softwareSkills: [{
                    skillName: "Docker",
                    fontAwesomeClassname: "simple-icons:docker",
                    style: {
                        color: "#1488C6"
                    }
                },
                {
                    skillName: "Kubernetes",
                    fontAwesomeClassname: "simple-icons:kubernetes",
                    style: {
                        color: "#326CE5"
                    }
                },
                {
                    skillName: "Travis CI",
                    fontAwesomeClassname: "simple-icons:travisci",
                    style: {
                        color: "#CC324A"
                    }
                },
                {
                    skillName: "Swagger",
                    fontAwesomeClassname: "simple-icons:swagger",
                    style: {
                        color: "#6D9A00"
                    }
                }
            ]
        }
    ]
};


// Education Page
const competitiveSites = {
    competitiveSites: [{
            siteName: "LeetCode",
            iconifyClassname: "simple-icons:leetcode",
            style: {
                color: "#0f0f10"
            },
            profileLink: "https://leetcode.com/flameslayer"
        },        
        {
            siteName: "Qwiklabs",
            iconifyClassname: "simple-icons:qwiklabs",
            style: {
                color: "#323754"
            },
            profileLink: "https://www.qwiklabs.com/public_profiles/7a5e6182-cb7f-4695-9d0d-a9d614fb798d"
        }
    ]
}

const degrees = {
    degrees: [{
        title: "Pune Institute of Computer Technology",
        subtitle: "B.E. in Information Technology",
        logo_path: "pict_logo.png",
        alt_name: "PICT Pune",
        duration: "2018 - Present",
        descriptions: [
            " "
            // "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS etc.",
            // "⚡ Apart from this, I have done courses on Full Stack Development.",
        ],
        website_link: "http://pict.edu"
    }]
}

const certifications = {
    certifications: [{
            title: "Django",
            subtitle: "- Udemy",
            logo_path: "udemy.png",
            certificate_link: "https://www.udemy.com/certificate/UC-c1f1323b-290c-49d5-9491-c7af5d039596/",
            alt_name: "Build a Backend REST API with Python & Django - Advanced",
            color_code: "#8C151599"
        },
        {
            title: "React JS",
            subtitle: "- Jogesh Muppala",
            logo_path: "coursera_logo.png",
            certificate_link: "https://www.coursera.org/account/accomplishments/records/C8KDXHWLAA6V",
            alt_name: "Coursera",
            color_code: "#2A73CC"
        }
        ,
        
    ]
};


// Experience Page
const experience = {
    title: "Experience",
    subtitle: "Internship and Volunteership",
    description: "I have interned with a startup as a Full-Stack Developer. I have also contributed to some open-source projects. I am open for other opportunities.",
    header_image_path: "experience.svg",
    sections: [{
            title: "Internships",
            experiences: [{
                    title: "Backend Engineering Intern",
                    company: "FamPay",
                    company_url: "https://fampay.in",
                    logo_path: "fampay.png",
                    duration: "Jan 2021 - April 2021",
                    location: "Work From Home",
                    description: "Worked on reccuring payments through UPI, Backend Analytics Events and JWT Authentication",
                    color: "#0071C5"
                },
                {
                    title: "Summer Fellowship",
                    company: "FOSSEE, IIT Bombay",
                    company_url: "https://fossee.in/",
                    logo_path: "iitb_logo.png",
                    duration: "May 2020 - June 2020",
                    location: "Work From Home",
                    description: "Developed a Video Processing System for spoken-tutorial.org which is an educational content portal. Technologies used include FFmpeg, Django, React, Celery, Redis, MySQL",
                    color: "#0071C5",
                    links: [
                        {
                            "name":"Project Report Link",
                            "link":"https://bit.ly/39DV917"
                        },
                        {
                            "name":"Project Link",
                            "link":"https://github.com/frg-fossee/spoken-website"
                        }
                    ]
                },
                {
                    title: "Project Intern",
                    company: "imocha",
                    company_url: "http://interviewmocha.com/",
                    logo_path: "im-logo.jpeg",
                    duration: "Oct 2019 - Present",
                    location: "Pune, Maharashtra",
                    description: "Developed an API based online IDE supporting 15+ languages. Robust and scalable in\n" +
                        "design. Built to handle high amounts of traffic. The project uses\n" +
                        "virtualization and implements sandboxing to run the code safely, restricting\n" +
                        "the access to system and resources. Also, a messaging queue is used to\n" +
                        "run submissions one by one, in order. Technologies used include Python,\n" +
                        "Django, RabbitMQ, PostgreSQL, Docker, Azure VM, etc. Also developing a Jupyterhub based online Data Science Simulator, deployed on a Kubernetes cluster.",
                    color: "#0071C5",
                    links: [
                        {
                            "name":"Mocha Online Compiler",
                            "link":"https://ide.interviewmocha.com"
                        }
                    ]
                },
            ],
        },
        {
            title: "Volunteerships",
            experiences: [
                {
                    title: "Cloud Lead",
                    company: "Developer Student Clubs, PICT",
                    // company_url: "https://gdgpune.org/",
                    logo_path: "gdg_logo.png",
                    location: "Pune, Maharashtra",
                    description: "As the cloud lead, I'm responsible for deploying projects made by DSC, PICT. I was the Google Cloud Facilitator and has successfully driven 30 Days of Google Cloud Program in my college",
                    color: "#D83B01",
                },{
                    title: "Technical Head",
                    company: "PICT ACM Student Chapter",
                    company_url: "https://pict.acm.org/",
                    logo_path: "pasc_logo.png",
                    duration: "June 2019 - April 2020",
                    location: "PICT Pune, Maharashtra",
                    description: "Technical Head of PASC( https://pict.acm.org/ ), a student chapter organization subsidiary of Association for Computing Machinery a worldwide learning society for computing. PASC has been awarded best ACM India Student Chapter Award for 3 times. As a member, I attend and also deliver sessions on various topics and technologies. Few of my works include an MCQ Solving Platform developed for tech fest Pulzion'19 along with other team members.",
                    color: "#4285F4",
                },                
                {
                    title: "Facebook Developer Circles",
                    company: "Facebook",
                    company_url: "https://developers.facebook.com/developercircles/",
                    logo_path: "fdc_logo.png",
                    location: "Pune, Maharashtra",
                    description: "Participated in various workshops and hackathons organised by FDC Pune",
                    color: "#000000",
                }
            ],
        },
    ],
};

// Projects Page
const projectsHeader = {
    title: "Projects",
    description: "My projects makes use of vast variety of latest technology tools. My best experience is to create backend projects and deploy them using cloud infrastructure.",
    avatar_image_path: "projects_image.svg",
};


// Contact Page
const contactPageData = {
    contactSection: {
        title: "Contact Me",
        profile_image_path: "animated_pratik.png",
        description: "I am available on almost every social media. You can message me. I can help you with React, Node, Django and Cloud Development.",
    },
    addressSection: {
        title: "Address",
        subtitle: "'Mauli', Ahinsa Nagar, Alodi, Dist.-Wardha, Maharashtra - 442001",
        avatar_image_path: "address_image.svg"
    },
    phoneSection: {
        title: "Phone Number",
        subtitle: "+91 7875323402",
    },
};

export {
    settings,
    greeting,
    socialMediaLinks,
    skills,
    competitiveSites,
    degrees,
    certifications,
    experience,
    projectsHeader,
    contactPageData,
};