import React, {Component} from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import SocialMedia from '../../components/socialMedia/SocialMedia';
import Button from '../../components/button/Button';
import AddressImg from './AddressImg';
import {Fade} from 'react-reveal';
import './ContactComponent.css';
import {contactPageData, greeting} from '../../portfolio.js';

const ContactData = contactPageData.contactSection;
const addressSection = contactPageData.addressSection;
// const phoneSection = contactPageData.phoneSection;

class Contact extends Component {
    render() {
        const theme = this.props.theme;
        return (
            <div className="contact-main">
                <Header theme={theme}/>
                <div className="basic-contact">
                    <Fade bottom duration={1000} distance="40px">
                        <div className="contact-heading-div">
                            <div className="contact-heading-img-div">
                                <img
                                    src={require(`../../assests/images/${ContactData["profile_image_path"]}`)}
                                    alt=""
                                />
                            </div>
                            <div className="contact-heading-text-div">
                                <h1 className="contact-heading-text" style={{color: theme.text}}>
                                    {ContactData["title"]}
                                </h1>
                                <p className="contact-header-detail-text subTitle" style={{color: theme.secondaryText}}>
                                    {ContactData["description"]}
                                </p>
                                <p className="contact-header-detail-text subTitle" style={{color: 'rgb(40 58 83)'}} >I'm Open for career opportunities!</p>   
                                <h1 className="address-heading-text" style={{color: theme.text}}>
                                    Email
                                </h1>
                                <p className="contact-header-detail-text subTitle" style={{color: theme.secondaryText}}>
                                    mail@pratikd.in
                                </p>
                                <SocialMedia/>
                                <div className="resume-btn-div">
                                    <Button
                                        text="See My Resume"
                                        newTab={true}
                                        href={greeting.resumeLink}
                                        theme={theme}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fade>
                    <Fade bottom duration={1000} distance="40px">
                        <div className="address-heading-div">

                            <div className="address-heading-text-div">
                                <h1 className="address-heading-text" style={{color: theme.text}}>
                                    {addressSection["title"]}
                                </h1>
                                <p className="contact-header-detail-text subTitle" style={{color: theme.secondaryText}}>
                                    'Wardha, Maharashtra, India'
                                </p>
                                                            </div>
                            <div className="contact-heading-img-div">
                                {/* <img
											src={require(`../../assests/images/${addressSection["avatar_image_path"]}`)}
											alt=""
										/> */}
                                <AddressImg theme={theme}/>
                            </div>
                        </div>
                    </Fade>
                </div>
                <Footer theme={this.props.theme} onToggle={this.props.onToggle}/>
            </div>
        );
    }
}

export default Contact;