import React from "react";
import ReactDOM from "react-dom";
import {BaseProvider, LightTheme} from "baseui";
import {Provider as StyletronProvider} from "styletron-react";
import {Client as Styletron} from "styletron-engine-atomic";


import "./index.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./assests/font-awesome/css/all.css";

const engine = new Styletron();
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(
        <StyletronProvider value={engine}>
            <BaseProvider theme={LightTheme}>
                <App/>
            </BaseProvider>
        </StyletronProvider>,
        document.getElementById("root")
    );
} else {
    ReactDOM.render(
        <StyletronProvider value={engine}>
            <BaseProvider theme={LightTheme}>
                <App/>
            </BaseProvider>
        </StyletronProvider>,
        document.getElementById("root")
    );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
