import React, {Component} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import Education from "../pages/education/EducationComponent";
import Experience from "../pages/experience/Experience";
import Contact from '../pages/contact/ContactComponent';
import Projects from '../pages/projects/Projects';
import Top from '../containers/topbutton/Top'
export default class Main extends Component {
    componentDidMount() {
        console.log('%c  _____.__                               .__                             \n' +
            '_/ ____\\  | _____    _____   ____   _____|  | _____  ___.__. ___________ \n' +
            '\\   __\\|  | \\__  \\  /     \\_/ __ \\ /  ___/  | \\__  \\<   |  |/ __ \\_  __ \\\n' +
            ' |  |  |  |__/ __ \\|  Y Y  \\  ___/ \\___ \\|  |__/ __ \\\\___  \\  ___/|  | \\/\n' +
            ' |__|  |____(____  /__|_|  /\\___  >____  >____(____  / ____|\\___  >__|   \n' +
            '                 \\/      \\/     \\/     \\/          \\/\\/         \\/       ', 'background: #222; color: #bada55')
    }

    render() {
        return (
            <div>
                <BrowserRouter basename="/">
                    <Switch>
                        <Route
                            path="/"
                            exact
                            render={(props) => (
                                <Home
                                    {...props}
                                    theme={this.props.theme}
                                />
                            )}
                        />
                        <Route
                            path="/home"
                            render={(props) => (
                                <Home
                                    {...props}
                                    theme={this.props.theme}
                                />
                            )}
                        />
                        <Route
                            path="/experience"
                            exact
                            render={(props) => (
                                <Experience
                                    {...props}
                                    theme={this.props.theme}
                                />
                            )}
                        />
                        <Route
                            path="/education"
                            render={(props) => (
                                <Education
                                    {...props}
                                    theme={this.props.theme}
                                />
                            )}
                        />
                        <Route
                            path="/contact"
                            render={(props) => (
                                <Contact
                                    {...props}
                                    theme={this.props.theme}
                                />
                            )}
                        />
                        {/* <Route
							path="/splash"
							render={(props) => (
								<Splash
									{...props}
									theme={this.props.theme}
								/>
							)}
						/> */}
                        <Route
                            path="/projects"
                            render={(props) => (
                                <Projects
                                    {...props}
                                    theme={this.props.theme}
                                />
                            )}
                        />
                    </Switch>
                </BrowserRouter>
                <Top />
            </div>
        );

    }
}
